.icon {
  width: 28%;
  object-fit: contain;
}

.ss01 {
  width: 34%;
  object-fit: contain;
}

.ss02 {
  width: 39%;
  object-fit: contain;
}

.imageInColumn {
  width: 88%;
  object-fit: contain;
}
