.citySelect {
  background: #F9E9ED;
  padding: 15px 20px 15px 15px;
  margin-bottom: 15px;
  border: none;
  border-bottom: solid 1px #B5B5B5;
  border-radius: unset;
  font-size: 16px;
  position: relative;
  background-image: url('../../../assets/images/arrow_down_icon.png');
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.mainOverlay {
  background-color: #000;
  transition: opacity 0.3s;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
}

.overlayShow {
  opacity: 0.3;
  visibility: visible;
}
