.border {
  border: solid 1px #000;
  padding: 5px;
  text-align: left;
}

.table {
  border-collapse: collapse;
  border: solid 1px #000;
  padding: 5px;
  word-break: break-all;
  font-family: sans-serif;
}

.link {
  color: #0E73DC;
  text-decoration: underline;
}
