.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: 0 15px 10px;
  border-bottom: solid 1px #B5B5B5;
}
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 15px 40px 15px;
  background-color: #f5f5f5;
}
.content {
  height: auto;
  padding-bottom: 110px;
}

