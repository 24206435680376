.bannerService01 {
  padding: 12px 0;
  width: 100%;
}
.bannerService02 {
  padding-bottom: 8px;
  width: 100%;
}
.thumbnail {
  width: 100%;
}
.productImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footerBanner {
  position: fixed;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
}

.footerBannerSafeAreaBox {
  position: fixed;
  background-color: white;
  bottom: 0;
  width: 100%;
  height: constant(safe-area-inset-bottom);
  height: env(safe-area-inset-bottom);
}
