.container {
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
  z-index: 20;
}

.container + div {
  height: 100vh;
  overflow: hidden;
}
