.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 6px;
  border-color: #DDDDDD;
  padding: 11px 35px 14px 11px;
  background-repeat: no-repeat;
  font-size: 18px;
  height: 52px;
  background-color: #ffffff;
  background-position: right 6px top 48%, 0 0;
  background-size: 15px 15px, 100%;
  appearance: none;       /* Remove default arrow */
  background-image: url('../../../../assets/images/arrowSelect.png'); /* Add custom arrow */ 
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis;
}

