.uploadBtn {
  padding: 15px;
  background: #d4d4d4;
  border-radius: 12px;
  display: flex;
  justify-content: center;
}
.fileInput {
  display: none;
}
.eventBanner {
  width: 100%;
}
.eventDescription {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Ubuntu, 'Helvetica Neue', sans-serif;
  text-align: start;
  line-height: 28px;
  font-size: 16px;
  padding: 15px 0;
  color: #333333;
}

.prizeImage {
  width: 100%;
  object-fit: contain;
}
