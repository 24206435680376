a, span {
  color: #0E73DC;
  text-decoration: underline;
  font: 16px system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}

.question {
  margin-bottom: 15px;
  margin-right: auto;
}

.onTopBtn {
  margin-top: 20px;
  text-align: end;
}
