.image1 {
  width: 36%;
  object-fit: contain;
}

.image2 {
  width: 36%;
  object-fit: contain;
}

.imageInColumn {
  width: 100%;
  min-height: 200px;
  object-fit: contain;
}
