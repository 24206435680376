.entryLabel {
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  padding: 5px 10px;
  text-decoration: none;
}
.eventImage {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
}
.titleEvent {
  color: #333333;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.dateEvent {
  color: #666666;
  font-size: 11px;
  margin-top: 4px;
}
@media only screen and (min-width: 480px) {
  .titleEvent {
    font-size: 18px;
    line-height: 1.5;
  }
  .entryLabel {
    font-size: 13px;
  }
  .dateEvent {
    font-size: 13px;
  }
}
