.serviceBanner01 {
  width: 100%;
  margin: 12px auto;
}
.serviceBanner02 {
  width: 100%;
  margin: 0 auto 8px;
}
.serviceBanner {
  width: 100%;
}
.serviceNote {
  color: #333;
  font-size: 15px;
  line-height: 1.333;
  padding-top: 16px;
}
.serviceTitle {
  color: #D95E2E;
  font-size: 16px;
  line-height: 1.333;
  padding-top: 24px;
}
.serviceTitleText {
  padding-top: 16px;
}
.serviceTitleText span {
  color: #D95E2E;
  font-size: 16px;
  line-height: 1.333;
  text-decoration: none;
}
.serviceDescription {
  font-size: 15px;
  line-height: 1.333;
  color: #333;
  padding-top: 8px;
}
.serviceDescription p {
  margin: 0;
}
.serviceDescription a {
  display: block;
  text-decoration: none;
  color: #D95E2E;
  text-align: center;
  border: 1px solid #d95e2e;
  border-radius: 20px;
  padding: 10px 0;
  margin-top: 10px;
}
.collapsible {
  display: flex;
  justify-content: space-between;
}
